import React from 'react'
import Layout from 'gatsby-theme-tailwindui/src/components/layout'
import { useSiteMetadata } from 'gatsby-theme-tailwindui/src/utils/use-site-metadata'
import QuoteComponent from '../components/quotes/QuoteComponent'
import QuotePageLayout from '../components/quotes/QuotePageLayout'

const QuotesByCategory = (props) => {
  const { quotes } = props.pageContext
  const { category } = props.pageContext
  const { globalNavMenu, globalContactMenu } = useSiteMetadata()
  const menuItems = globalNavMenu
  const contactMenu = globalContactMenu
  return (
    <Layout navMenuItems={menuItems} contactMenuItems={contactMenu}>
      <QuotePageLayout showAllButton>
        <h3 className="no-print">{category.title}</h3>
        {quotes.map((quote, i) => (
          <QuoteComponent key={i} data={quote} />
        ))}
      </QuotePageLayout>
    </Layout>
  )
}

export default QuotesByCategory
